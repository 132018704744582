module.exports = [{
      plugin: require('/var/lib/jenkins/workspace/ccm/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-143361173-1","head":false},
    },{
      plugin: require('/var/lib/jenkins/workspace/ccm/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/var/lib/jenkins/workspace/ccm/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
